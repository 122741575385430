import { CreateUserRequest } from "src/app/services/models/user/createUserRequest";
import { UpdateUserRequest } from "src/app/services/models/user/updateUserRequest";
import { User } from "src/app/services/models/user/user.model";
import { UserResponse } from "src/app/services/models/user/userResponse";

export class UserMapper {
    static MapUser(data: UserResponse): User{
        return {
            name: data.name,
            email: data.email,
            status: data.status,
            user_key: data.user_key,
            created_by: data.created_by,
            updated_by: data.updated_by,
        }
    }

    static MapCreateUserRequest(data: User): CreateUserRequest{
        return {
            name: data.name!,
            email: data.email!,
            status: data.status!,
            password: data.password!,
        }
    }

    static MapUpdateUserRequest(data: User): UpdateUserRequest{
        return {
            user_key: data.user_key!,
            name: data.name!,
            email: data.email!,
            password: data.password!,
        }
    }
}