<h1 mat-dialog-title>Edit User</h1>
<div class="mat-dialog-content-div" mat-dialog-content>
  <form class="login-form" [formGroup]="editUserFormGroup">
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Full Name</mat-label>
      <input type="text" formControlName="fullName" matInput placeholder="Full Name">
      <mat-error *ngIf="editUserFormGroup.hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Email</mat-label>
      <input type="email" formControlName="email" matInput placeholder="Ex. pat@example.com">
      <mat-error *ngIf="editUserFormGroup.hasError('email') && !editUserFormGroup.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="editUserFormGroup.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Password</mat-label>
      <input type="password" formControlName="password" matInput placeholder="********">
      <mat-error *ngIf="editUserFormGroup.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <!-- <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Password</mat-label>
      <input type="password" formControlName="password" matInput placeholder="********">
        <mat-error *ngIf="editUserFormGroup.hasError('required')">
              Password is <strong>required</strong>
        </mat-error>
  </mat-form-field> -->
  </form>
</div>
<div class="footer" mat-dialog-actions>
  <button class="mat-dialog-action-button" mat-raised-button color="primary" [disabled]="!editUserFormGroup.valid" cdkFocusInitial (click)="updateUser()">Save</button>
  <button class="mat-dialog-action-button" mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
</div>