import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/containers/login/login.component';
import { AppGuard } from './auth/guards/app.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { DefaultComponent } from './layouts/default/default.component';
import { BuildingsComponent } from './modules/buildings/buildings.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { InvestorsComponent } from './modules/investors/investors.component';
import { ProjectDetailsViewComponent } from './modules/projects/project-details-view/project-details-view.component';
import { ProjectsComponent } from './modules/projects/projects.component';
import { UsersComponent } from './modules/users/users.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AppGuard],
    canLoad: [AppGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'projects',
        component: ProjectsComponent
      },
      {
        path: 'projects/:key',
        component: ProjectDetailsViewComponent
      },
      {
        path: 'investors',
        component: InvestorsComponent
      },
      // {
      //   path: 'buildings/create',
      //   component: CreateBuildingComponent
      // },
      {
        path: 'buildings',
        component: BuildingsComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
