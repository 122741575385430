import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthModule } from '../../auth.module'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  emailFormGroup!: FormGroup;
  
  constructor(
    private formbuilder: FormBuilder,
    private myRoute: Router,
    private auth: AuthenticationService){ 
    this.createForm();
  }

  createForm() {
    this.emailFormGroup = this.formbuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }


  ngOnInit(): void {
  }

  login() {
    if(this.emailFormGroup.valid){
      this.auth.login(this.emailFormGroup.value).subscribe( result =>
        {
          if(result){
            this.myRoute.navigate(['/dashboard']);
          }
          else{
            alert("Wrong username password");
          }
        }          
      )
    }
  }
}
