import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectMapper } from 'src/app/helper/mapper/project-mapper';
import { Project } from 'src/app/services/models/project/project.model';
import { ProjectService } from 'src/app/services/project.service';
import { CreateProjectComponent } from './create-project/create-project.component';
import { EditProjectComponent } from './edit-project/edit-project.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  totalLength = 0;
  displayedColumns: string[] = ['name', 'address', 'actions', 'view'];
  projectDataSource: MatTableDataSource<Project>;

  @ViewChild(MatSort, { static:true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static:true }) paginator!: MatPaginator;

  constructor(private projectService: ProjectService,public dialog: MatDialog) { 
    this.projectDataSource = new MatTableDataSource<Project>();
    this.loadProjects();
  }

  loadProjects(){
    this.projectService.getProjects()
    .subscribe(
      resp => {
        const projects: Project[] = [];
        resp.forEach(project => {
          projects.push(ProjectMapper.MapProject(project));
        });
        this.projectDataSource.data = projects;
        this.totalLength = this.projectDataSource.data.length;
      }
    );
  }


  ngOnInit(): void {
    this.projectDataSource.sort = this.sort;
    this.projectDataSource.paginator = this.paginator;
  }

  openCreateProjectDialog(): void {
    const dialogRef = this.dialog.open(CreateProjectComponent, {
      width: '650px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadProjects();
    });
  }

  openEditProjectDialog(project: Project){
    const dialogRef = this.dialog.open(EditProjectComponent, {
      width: '650px',
      data: {
        project: project
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadProjects();
    });
  }

  applyFilter(event: any){
    const filterValue = (event.target as HTMLInputElement).value;

    this.projectDataSource.filter = filterValue.trim().toLowerCase();
  }

  view(project: Project){
    alert('View Clicked');
  }
}
