<header>
    <div class="hero">
        <h1>Login</h1>
        <hr>
        <p class="launch"></p>
        <form class="login-form" [formGroup]="emailFormGroup" (ngSubmit)="login()">
            <mat-form-field class="full-width-input" appearance="fill">
                <mat-label>Email</mat-label>
                <input type="email" formControlName="email" matInput placeholder="Ex. pat@example.com">
                <mat-icon matSuffix>person</mat-icon>
                <mat-error *ngIf="emailFormGroup.hasError('email') && !emailFormGroup.hasError('required')">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="emailFormGroup.hasError('required')">
                        Email is <strong>required</strong>
                  </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input" appearance="fill">
                <mat-label>Password</mat-label>
                <input type="password" formControlName="password" matInput placeholder="********">
                <mat-icon matSuffix>lock</mat-icon>
                  <mat-error *ngIf="emailFormGroup.hasError('required')">
                        Password is <strong>required</strong>
                  </mat-error>
            </mat-form-field>
            <br>
            <button mat-raised-button type="submit" color="primary" [disabled]="!emailFormGroup.valid" >Login</button>
          </form>
    </div>
</header>