<h2 mat-dialog-title>Create New Building</h2>
<mat-dialog-content>
  <form *ngIf="dataLoaded" class="login-form" [formGroup]="createBuildingFormGroup">
<div class="row">
    <mat-form-field  class="col-sm-6" appearance="fill">
      <mat-label>Project</mat-label>
      <mat-select required formControlName="projectRefKey" > <!-- [(ngModel)]="selectedProject" -->
        <mat-option (onSelectionChange)="changeEvent()" *ngFor="let project of projectList" [value]="project.project_key">
          {{ project.project_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-sm-3" appearance="fill">
      <mat-label>Building No</mat-label>
      <input type="text" required formControlName="buildingNumber" matInput placeholder="GA-1">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Building No is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-3" appearance="fill">
      <mat-label>Center Height</mat-label>
      <input type="text" formControlName="centerHeight" matInput placeholder="Center Height">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Center Height is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Side Height</mat-label>
      <input type="text" formControlName="sideHeight" matInput placeholder="Side Height">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Side Height is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Area Builtup</mat-label>
      <input type="text" formControlName="areaBuiltup" matInput placeholder="Area Builtup">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Area Builtup is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Area Carpet</mat-label>
      <input type="text" formControlName="areaCarpet" matInput placeholder="Area Carpet">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Area Carpet is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Gala</mat-label>
      <input type="text" formControlName="gala" matInput placeholder="Gala">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Gala is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Dock</mat-label>
      <input type="text" formControlName="dock" matInput placeholder="Dock">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Dock is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Shutter</mat-label>
      <input type="text" formControlName="shutter" matInput placeholder="Shutter">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Shutter is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Sliding Ramp</mat-label>
      <input type="text" formControlName="slidingRamp" matInput placeholder="Sliding Ramp">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Sliding Ramp is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Auto Dock</mat-label>
      <input type="text" formControlName="autoDock" matInput placeholder="Auto Dock">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Auto Dock is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>In Side Length</mat-label>
      <input type="text" formControlName="inSideLength" matInput placeholder="In Side Length">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        In Side Length is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    
    <mat-form-field  class="col-sm-4" appearance="fill">
      <mat-label>Permission Type</mat-label>
      <mat-select formControlName="typeOfPermissionCode" > <!-- [(ngModel)]="selectedTypeOfPermission" -->
        <mat-option (onSelectionChange)="changeEvent()" *ngFor="let typeOfPermission of typeOfPermissions" [value]="typeOfPermission.lookup_code">
          {{ typeOfPermission.lookup_value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field  class="col-sm-4" appearance="fill">
      <mat-label>Water Supply</mat-label>
      <mat-select formControlName="waterSupply"> <!--  [(ngModel)]="selectedTypeOfWaterSupply" -->
        <mat-option (onSelectionChange)="changeEvent()" *ngFor="let waterSupplyType of waterSupplyTypes" [value]="waterSupplyType.lookup_code">
          {{ waterSupplyType.lookup_value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field  class="col-sm-4" appearance="fill">
      <mat-label>Toilet</mat-label>
      <mat-select formControlName="toilet"> <!-- [(ngModel)]="selectedTypeOfToilet" -->
        <mat-option (onSelectionChange)="changeEvent()" *ngFor="let toiletType of toiletTypes" [value]="toiletType.lookup_code">
          {{ toiletType.lookup_value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Out Side Length</mat-label>
      <input type="text" formControlName="outSideLength" matInput placeholder="Out Side Length">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Out Side Length is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    
    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Front Side Road</mat-label>
      <input type="text" formControlName="frontSideRoad" matInput placeholder="Front Side Road">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Front Side Road is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Building Age</mat-label>
      <input type="text" formControlName="buildingAge" matInput placeholder="Building Age">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Building Age is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>No Of Lights</mat-label>
      <input type="text" formControlName="noOfLights" matInput placeholder="No Of Lights">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        No Of Lights is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Electricity Connection</mat-label>
      <input type="text" formControlName="electricityConnection" matInput placeholder="Electricity Connection">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Electricity Connection is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>No Of Toilet</mat-label>
      <input type="text" formControlName="noOfToilet" matInput placeholder="No Of Toilet">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        No Of Toilet is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    
    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Survey No</mat-label>
      <input type="text" formControlName="surveyNo" matInput placeholder="Survey No">
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Survey No is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-4" appearance="fill">
      <mat-label>Date Of Completion</mat-label>
      <input matInput formControlName="dateOfCompletion" placeholder="Date Of Completion" [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="createBuildingFormGroup.hasError('required')">
        Date Of Completion is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field  class="col-sm-4" appearance="fill">
      <mat-label>Water Supply</mat-label>
      <mat-select formControlName="borewellFacility"> <!-- [(ngModel)]="selectedTypeOfBorewellFacility" -->
        <mat-option (onSelectionChange)="changeEvent()" *ngFor="let borewellFacility of borewellFacilites" [value]="borewellFacility.lookup_code">
          {{ borewellFacility.lookup_value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle class="col-sm-4" formControlName="isFireSystem">Fire System</mat-slide-toggle>
    
    <mat-slide-toggle class="col-sm-4" formControlName="isFireNoc">Fire NOC</mat-slide-toggle>
    
    <mat-slide-toggle class="col-sm-4" formControlName="fireExit">Fire Exit</mat-slide-toggle>
    
    <mat-slide-toggle class="col-sm-4" formControlName="isStabilityCertificate">Stability Certificate</mat-slide-toggle>
    
    <mat-slide-toggle class="col-sm-4" formControlName="solarPower">Solar Power</mat-slide-toggle>
    
    <mat-slide-toggle class="col-sm-4" formControlName="mazanineFacility">Mazanine Facility</mat-slide-toggle>

    <mat-slide-toggle class="col-sm-4" formControlName="isNoc">Noc</mat-slide-toggle>

</div>
  </form>
  <mat-spinner *ngIf="!dataLoaded"></mat-spinner>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button  class="app-container float-right"  mat-raised-button color="primary" (click)='onNoClick()'>Cancel</button>
    <button  class="app-container float-right" [disabled]="!createBuildingFormGroup.valid" mat-raised-button color="primary" (click)='createBuilding()'>Save</button>
</mat-dialog-actions>