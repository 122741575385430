import { Investor } from "src/app/services/models/investor/investor.model";
import { InvestorResponse } from "src/app/services/models/investor/investorResponse";

export class InvestorMapper {
    static MapInvestor(data: InvestorResponse): Investor {
        return{   
            investorKey: data.investor_key,
            partyName: data.party_name,
            address: data.address,
            contactNo: data.contact_no,
            emailId: data.email,
            panNumber: data.pan_no,
            aadharNumber: data.aadhar_no,
            gstNumber: data.gst_no,
            created_by:data.created_by,          
            created_at:data.created_at,          
            updated_by:data.updated_by,          
            updated_at:data.updated_at,          
        }
    }
}