<mat-nav-list>
    <div class="profile-card">
        <div class="avatar">
            <app-avatar-photo-component name="{{authService.getName()}}"></app-avatar-photo-component>
        </div>
        <div>
            <h2>{{authService.getName()}}</h2>
            <h4>{{authService.getEmail()}}</h4>
        </div>
    </div>
    
    <mat-divider></mat-divider>

    <h2 matSubheader>Pages</h2>
    
    <a mat-list-item routerLinkActive="list-item-active"  routerLink="/dashboard">Dashboard</a>
    <a mat-list-item routerLinkActive="list-item-active"  routerLink="/buildings">Buildings</a>
    <a mat-list-item routerLinkActive="list-item-active"  routerLink="/projects">Projects</a>
    <a mat-list-item routerLinkActive="list-item-active"  routerLink="/users">Users</a>
    <a mat-list-item routerLinkActive="list-item-active"  routerLink="/investors">Investors</a>
    
    <mat-divider></mat-divider>
    
    <h2 matSubheader>Tools</h2>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/contacts">
        <mat-icon>import_contacts</mat-icon>
        Contacts
    </a>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/contacts">
        <mat-icon>phone</mat-icon>
        Leads
    </a>

</mat-nav-list>
