<mat-card class="mat-card-main">
  <mat-card-header>
    <mat-card-title>{{selectedProject.project_name}}</mat-card-title>
    <mat-card-subtitle>
      {{selectedProject.address_line_1}}, {{selectedProject.address_line_2}}, {{selectedProject.city}} - {{selectedProject.pincode}}, {{selectedProject.state}}, {{selectedProject.country}}
      <br>
      <a href="{{selectedProject.google_location}}" target="_blank">View On Map</a>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <div class="row">
      <div class="col-12 col-md-4" *ngFor="let item of selectedProject.buildings">
        <mat-card class="building-card">
          <mat-card-header>
            <mat-card-title>{{item.building_no}}</mat-card-title>  
            <mat-card-subtitle>
              Builtup Area: {{item.area_builtup}} | Carpet Area: {{item.area_carpet}}
              <br>
              Center Height: {{item.center_height}} | Side Height: {{item.side_height}}
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
          </mat-card-content>
        </mat-card>    
      </div>
    </div>
  </mat-card-content>
</mat-card>