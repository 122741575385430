import { Building } from "src/app/services/models/building/building.model";
import { BuildingResponse } from "src/app/services/models/building/buildingResponse";

export class BuildingMapper {
    static MapBuilding(data: BuildingResponse): Building {
        return{
            building_key:data.building_key,
            project_key:data.project_key,
            building_no:data.building_no,
            center_height:data.center_height,           
            side_height:data.side_height,
            area_builtup:data.area_builtup,
            area_carpet:data.area_carpet,         
            gala:data.gala,                
            dock:data.dock,                
            shutter:data.shutter,             
            sliding_ramp:data.sliding_ramp,        
            auto_dock:data.auto_dock,           
            in_side_length:data.in_side_length,      
            out_side_length:data.out_side_length,     
            type_of_permission_code:data.type_of_permission_code,
            is_noc:data.is_noc,              
            front_side_road:data.front_side_road,      
            is_fire_system:data.is_fire_system,  
            building_age:data.building_age,        
            is_stability_certificate:data.is_stability_certificate,
            is_fire_noc:data.is_fire_noc,       
            no_of_lights:data.no_of_lights,        
            electricity_connection:data.electricity_connection,
            water_supply_code:data.water_supply_code,   
            no_of_toilet:data.no_of_toilet,        
            toilet_code:data.toilet_code,         
            fire_exit:data.fire_exit,         
            solar_power:data.solar_power,
            mazanine_facility:data.mazanine_facility, 
            survey_no:data.survey_no,           
            date_of_completion:data.date_of_completion,  
            borewell_facility_code:data.borewell_facility_code,
            created_by:data.created_by,          
            created_at:data.created_at,          
            updated_by:data.updated_by,          
            updated_at:data.updated_at,          
        }
    }
}