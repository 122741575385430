import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvestorService } from 'src/app/services/investor.service';
import { Investor } from 'src/app/services/models/investor/investor.model';
import { UpdateInvestorRequest } from 'src/app/services/models/investor/updateInvestorRequest';

@Component({
  selector: 'app-edit-investor',
  templateUrl: './edit-investor.component.html',
  styleUrls: ['./edit-investor.component.scss']
})
export class EditInvestorComponent implements OnInit {

  public updateInvestorFormGroup!: FormGroup;
  panelOpenState = false;
  selectedInvestor: Investor

  constructor(
    public dialogRef: MatDialogRef<EditInvestorComponent>,
    private formbuilder: FormBuilder,
    private investorService: InvestorService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {investor: Investor}) {
      this.updateInvestorFormGroup = this.formbuilder.group({});
      this.selectedInvestor = data.investor;
      this.createForm();
  }

  createForm() {
    this.updateInvestorFormGroup = this.formbuilder.group({
      partyName: ['', Validators.required],
      address: ['', Validators.required],
      contactNumber: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      panNumber: ['', Validators.required],
      gstNumber: ['', Validators.required],
      aadharNumber: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.setForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setForm() {
    this.updateInvestorFormGroup.setValue({
      partyName: this.selectedInvestor.partyName ? this.selectedInvestor.partyName : '',
      address: this.selectedInvestor.address ? this.selectedInvestor.address : '',
      contactNumber: this.selectedInvestor.contactNo ? this.selectedInvestor.contactNo : '',
      email: this.selectedInvestor.emailId ? this.selectedInvestor.emailId : '',
      panNumber: this.selectedInvestor.panNumber ? this.selectedInvestor.panNumber : '',
      gstNumber: this.selectedInvestor.gstNumber ? this.selectedInvestor.gstNumber : '',
      aadharNumber: this.selectedInvestor.aadharNumber ? this.selectedInvestor.aadharNumber : '',
    })
  }

  createUser(){
    if(this.updateInvestorFormGroup.valid){
      var updateUserRequest: UpdateInvestorRequest = {
        investor_key: this.selectedInvestor.investorKey!,
        party_name: this.updateInvestorFormGroup.controls.partyName.value,
        address: this.updateInvestorFormGroup.controls.address.value,
        contact_no: this.updateInvestorFormGroup.controls.contactNumber.value,
        email: this.updateInvestorFormGroup.controls.email.value,
        pan_no: this.updateInvestorFormGroup.controls.panNumber.value,
        gst_no: this.updateInvestorFormGroup.controls.gstNumber.value,
        aadhar_no: this.updateInvestorFormGroup.controls.aadharNumber.value,
      }
      this.investorService.updateInvestor(updateUserRequest).subscribe(
        resp => {
          if(resp.status = 'success'){
            this.dialogRef.close();
            this.openSnackBar("Investor updated successfully","");
          }
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
