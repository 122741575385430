import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import { Constants } from '../config/constants';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { BuildingResponse } from './models/building/buildingResponse';
import { CreateProjectRequest } from './models/project/createProjectRequest';
import { ProjectResponse } from './models/project/projectResponse';
import { UpdateProjectRequest } from './models/project/updateProjectRequest';
import { ApiResponse } from './models/response';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  
  private handleError: HandleError;

  constructor(private http: HttpClient, 
    private httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('ProjectService');
   }

  public getProjects(): Observable<ProjectResponse[]> {
    return this.http.get<ApiResponse<ProjectResponse[]>>(`${baseUrl}/${Constants.getProjects}`)
    .pipe(
      map(res => res.data),
      catchError(this.handleError<ProjectResponse[]>('getProjects'))
    );
  }

  public getBuildingsByProjectKey(projectKey: string): Observable<BuildingResponse[]> {
    var formData: any = new FormData();
    formData.append("project_key",projectKey);
    return this.http.post<ApiResponse<BuildingResponse[]>>(`${baseUrl}/${Constants.getBuildingsByProjectKey}`,formData)
    .pipe(
      map(res => res.data),
      catchError(this.handleError<BuildingResponse[]>('getBuildingsByProjectKey'))
    );
  }

  public getProjectByKey(projectKey: string): Observable<ApiResponse<ProjectResponse>> {
    var formData: any = new FormData();
    formData.append("project_key",projectKey);
    return this.http.post<ApiResponse<ProjectResponse>>(`${baseUrl}/${Constants.getByKey}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<ProjectResponse>>('getProjectByKey'))
    );
  }

  public createProject(project: CreateProjectRequest): Observable<ApiResponse<ProjectResponse>> {
    var formData: any = new FormData();
    formData.append("project_name", project.project_name);
    formData.append("near_by", project.near_by);
    formData.append("google_location", project.google_location);
    formData.append("address_line_1", project.address_line_1);
    formData.append("address_line_2", project.address_line_2);
    formData.append("pincode", project.pincode);
    formData.append("country", project.country);
    formData.append("state", project.state);
    formData.append("city", project.city);
    return this.http.post<ApiResponse<ProjectResponse>>(`${baseUrl}/${Constants.createProject}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<ProjectResponse>>('createProject'))
    );
  }

  public updateProject(project: UpdateProjectRequest): Observable<ApiResponse<ProjectResponse>> {
    var formData: any = new FormData();
    formData.append("project_key", project.project_key);
    formData.append("project_name", project.project_name);
    formData.append("near_by", project.near_by);
    formData.append("google_location", project.google_location);
    formData.append("address_line_1", project.address_line_1);
    formData.append("address_line_2", project.address_line_2);
    formData.append("pincode", project.pincode);
    formData.append("country", project.country);
    formData.append("state", project.state);
    formData.append("city", project.city);
    return this.http.post<ApiResponse<ProjectResponse>>(`${baseUrl}/${Constants.updateProject}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<ProjectResponse>>('updateProject'))
    );
  }
}
