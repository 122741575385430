import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import { Constants } from '../config/constants';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { ApiResponse } from './models/response';
import { CreateUserRequest } from './models/user/createUserRequest';
import { UpdateUserRequest } from './models/user/updateUserRequest';
import { UserResponse } from './models/user/userResponse';

@Injectable({
  providedIn: 'root'
})
export class UsersService  {

  private handleError: HandleError;

  constructor(private http: HttpClient, 
    private httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('UserService');
   }

  public getUsers(): Observable<UserResponse[]> {
    return this.http.get<ApiResponse<UserResponse[]>>(`${baseUrl}/${Constants.getUsers}`)
    .pipe(
      map(res => res.data),
      catchError(this.handleError<UserResponse[]>('getUsers'))
    );
  }

  public createUser(user: CreateUserRequest): Observable<ApiResponse<UserResponse>> {
    var formData: any = new FormData();
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("password", user.password);
    formData.append("status", user.status);
    return this.http.post<ApiResponse<UserResponse>>(`${baseUrl}/${Constants.createUser}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<UserResponse>>('createUser'))
    );
  }

  public updateUser(user: UpdateUserRequest): Observable<ApiResponse<UserResponse>> {
    var formData: any = new FormData();
    formData.append("id", user.user_key);
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("password", user.password);
    return this.http.post<ApiResponse<UserResponse>>(`${baseUrl}/${Constants.updateUser}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<UserResponse>>('updateUser'))
    );
  }
}