import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { Constants } from 'src/app/config/constants';
import { PickupListCode } from 'src/app/config/pickupListCode.enum';
import { PicklistMapper } from 'src/app/helper/mapper/picklist-mapper';
import { ProjectMapper } from 'src/app/helper/mapper/project-mapper';
import { BuildingService } from 'src/app/services/building.service';
import { Building } from 'src/app/services/models/building/building.model';
import { UpdateBuildingRequest } from 'src/app/services/models/building/updateBuildingRequest';
import { PickList } from 'src/app/services/models/picklist/picklistItem.model';
import { PickListResponse } from 'src/app/services/models/picklist/pickListResponse';
import { Project } from 'src/app/services/models/project/project.model';
import { ProjectResponse } from 'src/app/services/models/project/projectResponse';
import { PickListService } from 'src/app/services/pick-list.service';
import { ProjectService } from 'src/app/services/project.service';
import { EditProjectComponent } from '../../projects/edit-project/edit-project.component';

@Component({
  selector: 'app-edit-building',
  templateUrl: './edit-building.component.html',
  styleUrls: ['./edit-building.component.scss']
})
export class EditBuildingComponent implements OnInit {

  public editBuildingFormGroup!: FormGroup;
  panelOpenState = false;
  selectedBuilding: Building

  projectList: Project[] = [];
  typeOfPermissions: PickList[] = [];
  waterSupplyTypes: PickList[] = [];
  toiletTypes: PickList[] = [];
  borewellFacilites: PickList[] = [];
  
  
  dataLoaded: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditBuildingComponent>,
    private formbuilder: FormBuilder,
    private projectService: ProjectService,
    private pickListService: PickListService,
    private buildingService: BuildingService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {building: Building}) {
      this.createForm();
      this.selectedBuilding = data.building;
      console.log(this.selectedBuilding);
  }

  ngOnInit(): void {
    this.setForm();
  }

  createForm() {
    this.editBuildingFormGroup = this.formbuilder.group({
      projectRefKey: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      centerHeight: [0, ],
      sideHeight: [0, ],
      areaBuiltup: [0, ],
      areaCarpet: [0, ],
      gala: [0, ],
      dock: [0, ],
      shutter: [0, ],
      slidingRamp: [0, ],
      autoDock: [0, ],
      inSideLength: [0, ],
      outSideLength: [0, ],
      typeOfPermissionCode: ['', ],
      isNoc : [false],
      frontSideRoad : [0, ],
      isFireSystem : [false, ],
      buildingAge : [0, ],
      isStabilityCertificate : [false, ],
      isFireNoc : [false, ],
      noOfLights : [0, ],
      electricityConnection : ['', ],
      waterSupply : ['', ],
      noOfToilet : [0, ],
      toilet : [0, ],
      fireExit : [false,],
      solarPower : [false,],
      mazanineFacility : [false,],
      surveyNo : [0,],
      dateOfCompletion : [null, ],
      borewellFacility : ['', ]
    });
    this.dataLoaded = false;
    this.loadLookupValues();
  }

  loadLookupValues() {
    this.projectService.getProjects()
    .pipe(
      take(1)
    )
    .subscribe((projects: ProjectResponse[]) => {
      if(projects.length > 0) {
        this.projectList = projects.map(project => ProjectMapper.MapProject(project));
        this.dataLoaded = true;
      }
    })
    this.pickListService.getList(PickupListCode.TypeOfPermission)
    .pipe(
      take(1)
    )
    .subscribe((typeOfPermissions: PickListResponse[]) => {
      if(typeOfPermissions.length > 0){
        this.typeOfPermissions = typeOfPermissions.map(typeOfPermission => PicklistMapper.MapPickListItem(typeOfPermission));
      }
    })
    this.pickListService.getList(PickupListCode.WaterSupplyType)
    .pipe(
      take(1)
    )
    .subscribe((waterSupplyTypes: PickListResponse[]) => {
      if(waterSupplyTypes.length > 0){
        this.waterSupplyTypes = waterSupplyTypes.map(waterSupplyType => PicklistMapper.MapPickListItem(waterSupplyType));
      }
    })

    this.pickListService.getList(PickupListCode.ToiletType)
    .pipe(
      take(1)
    )
    .subscribe((toiletTypes: PickListResponse[]) => {
      if(toiletTypes.length > 0){
        this.toiletTypes = toiletTypes.map(toiletType => PicklistMapper.MapPickListItem(toiletType));
      }
    })

    this.pickListService.getList(PickupListCode.BorewellFacilityType)
    .pipe(
      take(1)
    )
    .subscribe((borewellFacilites: PickListResponse[]) => {
      if(borewellFacilites.length > 0){
        this.borewellFacilites = borewellFacilites.map(borewellFacility => PicklistMapper.MapPickListItem(borewellFacility));
      }
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateBuilding(){
    if(this.editBuildingFormGroup.valid){
      const buildingDetails = this.editBuildingFormGroup.getRawValue();
      var updateBuildingRequest: UpdateBuildingRequest = {
        building_key: this.selectedBuilding.building_key!,
        project_key: this.editBuildingFormGroup.controls.projectRefKey.value,
        building_no: this.editBuildingFormGroup.controls.buildingNumber.value,
        center_height: this.editBuildingFormGroup.controls.centerHeight.value,
        side_height: this.editBuildingFormGroup.controls.sideHeight.value,
        area_builtup: this.editBuildingFormGroup.controls.areaBuiltup.value,
        area_carpet: this.editBuildingFormGroup.controls.areaCarpet.value,
        gala: this.editBuildingFormGroup.controls.gala.value,
        dock: this.editBuildingFormGroup.controls.dock.value,
        shutter: this.editBuildingFormGroup.controls.shutter.value,
        sliding_ramp: this.editBuildingFormGroup.controls.slidingRamp.value,
        auto_dock: this.editBuildingFormGroup.controls.autoDock.value,
        in_side_length: this.editBuildingFormGroup.controls.inSideLength.value,
        out_side_length: this.editBuildingFormGroup.controls.outSideLength.value,
        type_of_permission_code: this.editBuildingFormGroup.controls.typeOfPermissionCode.value,
        is_noc: this.editBuildingFormGroup.controls.isNoc.value,
        front_side_road: this.editBuildingFormGroup.controls.frontSideRoad.value,
        is_fire_system: this.editBuildingFormGroup.controls.isFireSystem.value,
        building_age: this.editBuildingFormGroup.controls.buildingAge.value,
        is_stability_certificate: this.editBuildingFormGroup.controls.isStabilityCertificate.value,
        is_fire_noc: this.editBuildingFormGroup.controls.isFireNoc.value,
        no_of_lights: this.editBuildingFormGroup.controls.noOfLights.value,
        electricity_connection: this.editBuildingFormGroup.controls.electricityConnection.value,
        water_supply: this.editBuildingFormGroup.controls.waterSupply.value,
        no_of_toilet: this.editBuildingFormGroup.controls.noOfToilet.value,
        toilet: this.editBuildingFormGroup.controls.toilet.value,
        fire_exit: this.editBuildingFormGroup.controls.fireExit.value,
        solar_power: this.editBuildingFormGroup.controls.solarPower.value,
        mazanine_facility: this.editBuildingFormGroup.controls.mazanineFacility.value,
        survey_no: this.editBuildingFormGroup.controls.surveyNo.value,
        date_of_completion: buildingDetails.dateOfCompletion,//this.editBuildingFormGroup.controls.dateOfCompletion.value,
        borewell_facility: this.editBuildingFormGroup.controls.borewellFacility.value,
      }
      this.buildingService.updateBuilding(updateBuildingRequest).subscribe(
        resp => {
          if(resp.status = 'success'){
            this.dialogRef.close();
            this.openSnackBar("Building updated successfully","");
          }
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  setForm() {
    this.editBuildingFormGroup.setValue({
      projectRefKey: this.selectedBuilding.project_key ? this.selectedBuilding.project_key : '', 
      buildingNumber: this.selectedBuilding.building_no ? this.selectedBuilding.building_no : '', 
      centerHeight: this.selectedBuilding.center_height ? this.selectedBuilding.center_height : '',
      sideHeight: this.selectedBuilding.side_height ? this.selectedBuilding.side_height : '',
      areaBuiltup: this.selectedBuilding.area_builtup ? this.selectedBuilding.area_builtup : '',
      areaCarpet: this.selectedBuilding.area_carpet ? this.selectedBuilding.area_carpet : '',
      gala: this.selectedBuilding.gala ? this.selectedBuilding.gala : '',
      dock: this.selectedBuilding.dock ? this.selectedBuilding.dock : '',
      shutter: this.selectedBuilding.shutter ? this.selectedBuilding.shutter : '',
      slidingRamp: this.selectedBuilding.sliding_ramp ? this.selectedBuilding.sliding_ramp : '',
      autoDock: this.selectedBuilding.auto_dock ? this.selectedBuilding.auto_dock : '',
      inSideLength: this.selectedBuilding.in_side_length ? this.selectedBuilding.in_side_length : '',
      outSideLength: this.selectedBuilding.out_side_length ? this.selectedBuilding.out_side_length : '',
      typeOfPermissionCode: this.selectedBuilding.type_of_permission_code ? this.selectedBuilding.type_of_permission_code : '',
      isNoc : this.selectedBuilding.is_noc ? this.selectedBuilding.is_noc : '',
      frontSideRoad : this.selectedBuilding.front_side_road ? this.selectedBuilding.front_side_road : '',
      isFireSystem : this.selectedBuilding.is_fire_system ? this.selectedBuilding.is_fire_system : '',
      buildingAge : this.selectedBuilding.building_age ? this.selectedBuilding.building_age : '',
      isStabilityCertificate : this.selectedBuilding.is_stability_certificate ? this.selectedBuilding.is_stability_certificate : '',
      isFireNoc : this.selectedBuilding.is_fire_noc ? this.selectedBuilding.is_fire_noc : '',
      noOfLights : this.selectedBuilding.no_of_lights ? this.selectedBuilding.no_of_lights : '',
      electricityConnection : this.selectedBuilding.electricity_connection ? this.selectedBuilding.electricity_connection : '',
      waterSupply : this.selectedBuilding.water_supply_code ? this.selectedBuilding.water_supply_code : '',
      noOfToilet : this.selectedBuilding.no_of_toilet ? this.selectedBuilding.no_of_toilet : '',
      toilet : this.selectedBuilding.toilet_code ? this.selectedBuilding.toilet_code : '',
      fireExit : this.selectedBuilding.fire_exit ? this.selectedBuilding.fire_exit : '',
      solarPower : this.selectedBuilding.solar_power ? this.selectedBuilding.solar_power : '',
      mazanineFacility : this.selectedBuilding.mazanine_facility ? this.selectedBuilding.mazanine_facility : '',
      surveyNo : this.selectedBuilding.survey_no ? this.selectedBuilding.survey_no : '',
      dateOfCompletion : this.selectedBuilding.date_of_completion ? new Date(this.selectedBuilding.date_of_completion) : '',
      borewellFacility : this.selectedBuilding.borewell_facility_code ? this.selectedBuilding.borewell_facility_code : '',
    })
  }

}
