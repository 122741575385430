import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateUserRequest } from 'src/app/services/models/user/updateUserRequest';
import { User } from 'src/app/services/models/user/user.model';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public editUserFormGroup!: FormGroup;
  panelOpenState = false;
  selectedUser: User

  constructor(
    private formbuilder: FormBuilder,
    private userService: UsersService,
    public dialogRef: MatDialogRef<EditUserComponent>,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {user: User}) 
  { 
    this.editUserFormGroup = this.formbuilder.group({});
    this.selectedUser = data.user;
    this.createForm();
  }
  
  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.editUserFormGroup.setValue({
      fullName: this.selectedUser.name ? this.selectedUser.name : '', 
      email: this.selectedUser.email ? this.selectedUser.email : '' ,
      password: '' ,
    })
  }

  createForm() {
    this.editUserFormGroup = this.formbuilder.group({
      fullName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateUser(){
    if(this.editUserFormGroup.valid){
      var updateUserRequest: UpdateUserRequest =  {
        user_key: this.selectedUser.user_key!,
        name: this.editUserFormGroup.controls.fullName.value,
        email: this.editUserFormGroup.controls.email.value,
        password: this.editUserFormGroup.controls.password.value,
      }
      this.userService.updateUser(updateUserRequest).subscribe(
        resp => {
          if(resp.status = 'success'){
            this.dialogRef.close();
            this.openSnackBar("User updated successfully","");
          }
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
