<h1 mat-dialog-title>Create Investor</h1>
<div class="mat-dialog-content-div" mat-dialog-content>
  <form class="login-form" [formGroup]="createInvestorFormGroup">
    <!-- Party Name Input -->
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Party Name</mat-label>
      <input type="text" formControlName="partyName" matInput placeholder="Party Name">
      <mat-error *ngIf="createInvestorFormGroup.hasError('required')">
        Party Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <!-- Address Name Input -->
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Address</mat-label>
      <input type="text" formControlName="address" matInput placeholder="Address">
      <mat-error *ngIf="createInvestorFormGroup.hasError('required')">
        Address is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <!-- Contact Number Name Input -->
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Contact Number</mat-label>
      <input type="text" formControlName="contactNumber" matInput placeholder="Contact Number">
    </mat-form-field>
    <!-- Party Name Input -->
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Email</mat-label>
      <input type="email" formControlName="email" matInput placeholder="Ex. pat@example.com">
      <mat-error *ngIf="createInvestorFormGroup.hasError('email') && !createInvestorFormGroup.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="createInvestorFormGroup.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <!-- PAN Number Input -->
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>PAN Number</mat-label>
      <input type="text" formControlName="panNumber" matInput placeholder="PAN Number">
      <mat-error *ngIf="createInvestorFormGroup.hasError('required')">
        PAN Number is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <!-- GST Number Input -->
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>GST Number</mat-label>
      <input type="text" formControlName="gstNumber" matInput placeholder="GST Number">
      <mat-error *ngIf="createInvestorFormGroup.hasError('required')">
        GST Number is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <!-- GST Number Input -->
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Aadhar Number</mat-label>
      <input type="text" formControlName="aadharNumber" matInput placeholder="Aadhar Number">
      <mat-error *ngIf="createInvestorFormGroup.hasError('required')">
        Aadhar Number is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div class="footer" mat-dialog-actions>
  <button class="mat-dialog-action-button" mat-raised-button color="primary" [disabled]="!createInvestorFormGroup.valid" cdkFocusInitial (click)="createUser()">Save</button>
  <button class="mat-dialog-action-button" mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
</div>