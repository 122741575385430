import { Observable } from "rxjs";
import { Building } from "../building/building.model";

export class Project {
  project_key?: string;
  project_name?: string;
  near_by?: string;
  google_location?: string;
  address_line_1?: string;
  address_line_2?: string;
  pincode?: string;
  country?: string;
  state?: string;
  city?: string;
  buildings: Building[] = []
  created_by?: string;
  created_at?: string;
  updated_by?: string;
  updated_at?: string;
}
