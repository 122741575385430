import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import { Constants } from '../config/constants';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { CreateInvestorRequest } from './models/investor/createInvestorRequest';
import { InvestorResponse } from './models/investor/investorResponse';
import { UpdateInvestorRequest } from './models/investor/updateInvestorRequest';
import { ApiResponse } from './models/response';

@Injectable({
  providedIn: 'root'
})
export class InvestorService {

  private handleError: HandleError;

  constructor(private http: HttpClient, 
    public datepipe: DatePipe,
    private httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('InvestorService');
  }

  public getInvestors(): Observable<InvestorResponse[]> {
    return this.http.get<ApiResponse<InvestorResponse[]>>(`${baseUrl}/${Constants.getInvestors}`)
    .pipe(
      map(res => res.data),
      catchError(this.handleError<InvestorResponse[]>('getBuildings'))
    );
  }

  public createInvestor(investor: CreateInvestorRequest): Observable<ApiResponse<InvestorResponse>> {
    var formData: any = new FormData();
    formData.append("party_name", investor.party_name);
    formData.append("address", investor.address);
    formData.append("contact_no", investor.contact_no);
    formData.append("email", investor.email);
    formData.append("pan_no", investor.pan_no);
    formData.append("aadhar_no", investor.aadhar_no);
    formData.append("gst_no", investor.gst_no);
    return this.http.post<ApiResponse<InvestorResponse>>(`${baseUrl}/${Constants.createInvestor}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<InvestorResponse>>('createInvestor'))
    );
  }

  public updateInvestor(investor: UpdateInvestorRequest): Observable<ApiResponse<InvestorResponse>> {
    var formData: any = new FormData();
    formData.append("investor_key", investor.investor_key);
    formData.append("party_name", investor.party_name);
    formData.append("address", investor.address);
    formData.append("email", investor.email);
    formData.append("contact_no", investor.contact_no);
    formData.append("pan_no", investor.pan_no);
    formData.append("aadhar_no", investor.aadhar_no);
    formData.append("gst_no", investor.gst_no);
    return this.http.post<ApiResponse<InvestorResponse>>(`${baseUrl}/${Constants.updateInvestor}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<InvestorResponse>>('updateInvestor'))
    );
  }

}