import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Project } from 'src/app/services/models/project/project.model';
import { UpdateProjectRequest } from 'src/app/services/models/project/updateProjectRequest';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {

  public editProjectFormGroup!: FormGroup;
  panelOpenState = false;
  selectedProject: Project

  constructor( public dialogRef: MatDialogRef<EditProjectComponent>,
    private formbuilder: FormBuilder,
    private projectService: ProjectService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {project: Project}) {
      this.createForm();
      this.selectedProject = data.project;
  }


  createForm() {
   this.editProjectFormGroup = this.formbuilder.group({
     projectName: ['', Validators.required],
     nearBy: ['', Validators.required],
     googleLocation: [''],
     addressLine1: ['', Validators.required],
     addressLine2: [''],
     pincode: ['', Validators.required],
     country: ['', Validators.required],
     state: ['', Validators.required],
     city: ['', Validators.required],
   });
  }
  
  ngOnInit(): void {
    this.setForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateProject(){
    if(this.editProjectFormGroup.valid){
      var udateProjectRequest: UpdateProjectRequest = {
        project_key: this.selectedProject.project_key!,
        project_name: this.editProjectFormGroup.controls.projectName.value,
        near_by: this.editProjectFormGroup.controls.nearBy.value,
        google_location: this.editProjectFormGroup.controls.googleLocation.value,
        address_line_1: this.editProjectFormGroup.controls.addressLine1.value,
        address_line_2: this.editProjectFormGroup.controls.addressLine2.value,
        pincode: this.editProjectFormGroup.controls.pincode.value,
        country: this.editProjectFormGroup.controls.country.value,
        state: this.editProjectFormGroup.controls.state.value,
        city: this.editProjectFormGroup.controls.city.value,
      }
      this.projectService.updateProject(udateProjectRequest).subscribe(
        resp => {
          if(resp.status = 'success'){
            this.dialogRef.close();
            this.openSnackBar("Project updated successfully","");
          }
        }
      );
    }
  }

  setForm() {
    this.editProjectFormGroup.setValue({
      projectName: this.selectedProject.project_name ? this.selectedProject.project_name : '', 
      nearBy: this.selectedProject.near_by ? this.selectedProject.near_by : '' ,
      googleLocation: this.selectedProject.google_location ? this.selectedProject.google_location : '' ,
      addressLine1: this.selectedProject.address_line_1 ? this.selectedProject.address_line_1 : '' ,
      addressLine2: this.selectedProject.near_by ? this.selectedProject.near_by : '' ,
      pincode: this.selectedProject.pincode ? this.selectedProject.pincode : '' ,
      country: this.selectedProject.country ? this.selectedProject.country : '' ,
      state: this.selectedProject.state ? this.selectedProject.state : '' ,
      city: this.selectedProject.city ? this.selectedProject.city : '' ,
    })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
