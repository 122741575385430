export class Constants {
    public static login: string = 'authorization.php?type=login'; 

    public static getUsers: string = 'users.php?type=get_list'; 
    public static createUser: string = 'users.php?type=create'; 
    public static updateUser: string = 'users.php?type=update'; 
    public static deleteUser: string = 'users.php?type=delete'; 

    public static getProjects: string = 'project.php?type=get_list'; 
    public static getByKey: string = 'project.php?type=get_by_key'; 
    public static getBuildingsByProjectKey: string = 'project.php?type=get_buildings'; 
    public static createProject: string = 'project.php?type=create'; 
    public static updateProject: string = 'project.php?type=update'; 
    public static deleteProject: string = 'project.php?type=delete'; 

    public static getBuildings: string = 'building.php?type=get_list'; 
    public static getBuildingByKey: string = 'building.php?type=get_by_key'; 
    public static createBuilding: string = 'building.php?type=create'; 
    public static updateBuilding: string = 'building.php?type=update'; 
    public static deleteBuildings: string = 'building.php?type=delete'; 

    public static getInvestors: string = 'investor.php?type=get_list'; 
    public static getInvestorByKey: string = 'investor.php?type=get_by_key'; 
    public static createInvestor: string = 'investor.php?type=create'; 
    public static updateInvestor: string = 'investor.php?type=update'; 
    public static deleteInvestor: string = 'investor.php?type=delete'; 

    public static getList: string = 'commonlistvalues.php?type=get_list&list_code='; 
} 

