import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvestorMapper } from 'src/app/helper/mapper/investor-mappr';
import { Investor } from 'src/app/services/models/investor/investor.model';
import { InvestorService } from 'src/app/services/investor.service';
import { CreateInvestorComponent } from './create-investor/create-investor.component';
import { EditInvestorComponent } from './edit-investor/edit-investor.component';

@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.scss']
})
export class InvestorsComponent implements OnInit {

  totalLength = 0;
  displayedColumns: string[] = ['partyName', 'actions'];
  investorDataSource: MatTableDataSource<Investor>;

  @ViewChild(MatSort, { static:true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static:true }) paginator!: MatPaginator;

  constructor(private InvestorService: InvestorService,public dialog: MatDialog) { 
    this.investorDataSource = new MatTableDataSource<Investor>();
    this.loadInvestors();
  }

  loadInvestors(){
    this.InvestorService.getInvestors()
    .subscribe(
      resp => {
        const Investors: Investor[] = [];
        resp.forEach(Investor => {
          Investors.push(InvestorMapper.MapInvestor(Investor));
        });
        this.investorDataSource.data = Investors;
        this.totalLength = this.investorDataSource.data.length;
      }
    );
  }


  ngOnInit(): void {
    this.investorDataSource.sort = this.sort;
    this.investorDataSource.paginator = this.paginator;
  }

  openCreateInvestorDialog(): void {
    const dialogRef = this.dialog.open(CreateInvestorComponent, {
      width: '650px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadInvestors();
    });
  }

  openEditInvestorDialog(investor: Investor){
    const dialogRef = this.dialog.open(EditInvestorComponent, {
      width: '650px',
      data: {
        investor: investor
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadInvestors();
    });
  }

  applyFilter(event: any){
    const filterValue = (event.target as HTMLInputElement).value;

    this.investorDataSource.filter = filterValue.trim().toLowerCase();
  }

  view(Investor: Investor){
    alert('View Clicked');
  }

}
