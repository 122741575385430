import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import { Constants } from '../config/constants';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { BuildingResponse } from './models/building/buildingResponse';
import { CreateBuildingRequest } from './models/building/createBuildingRequest';
import { UpdateBuildingRequest } from './models/building/updateBuildingRequest';
import { ApiResponse } from './models/response';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  private handleError: HandleError;

  constructor(private http: HttpClient, 
    public datepipe: DatePipe,
    private httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('BuildingService');
   }

  public getBuildings(): Observable<BuildingResponse[]> {
    return this.http.get<ApiResponse<BuildingResponse[]>>(`${baseUrl}/${Constants.getBuildings}`)
    .pipe(
      map(res => res.data),
      catchError(this.handleError<BuildingResponse[]>('getBuildings'))
    );
  }

  public getBuildingByKey(buildingKey: string): Observable<ApiResponse<BuildingResponse>> {
    var formData: any = new FormData();
    formData.append("building_key",buildingKey);
    return this.http.post<ApiResponse<BuildingResponse>>(`${baseUrl}/${Constants.getBuildingByKey}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<BuildingResponse>>('getBuildingByKey'))
    );
  }

  public createBuilding(building: CreateBuildingRequest): Observable<ApiResponse<BuildingResponse>> {
    var formData: any = new FormData();
    formData.append("project_key", building.project_key);
    formData.append("building_no", building.building_no);
    formData.append("center_height", building.center_height);
    formData.append("side_height", building.side_height);
    formData.append("area_builtup", building.area_builtup);
    formData.append("area_carpet", building.area_carpet);
    formData.append("gala", building.gala);
    formData.append("dock", building.dock);
    formData.append("shutter", building.shutter);
    formData.append("sliding_ramp", building.sliding_ramp);
    formData.append("auto_dock", building.auto_dock);
    formData.append("in_side_length", building.in_side_length);
    formData.append("out_side_length", building.out_side_length);
    formData.append("type_of_permission_code", building.type_of_permission_code);
    formData.append("is_noc", building.is_noc ? 1: 0);
    formData.append("front_side_road", building.front_side_road);
    formData.append("is_fire_system", building.is_fire_system ? 1: 0);
    formData.append("building_age", building.building_age);
    formData.append("is_stability_certificate", building.is_stability_certificate ? 1: 0);
    formData.append("is_fire_noc", building.is_fire_noc ? 1: 0);
    formData.append("no_of_lights", building.no_of_lights);
    formData.append("electricity_connection", building.electricity_connection);
    formData.append("water_supply_code", building.water_supply);
    formData.append("no_of_toilet", building.no_of_toilet);
    formData.append("toilet_code", building.toilet);
    formData.append("fire_exit", building.fire_exit ? 1: 0);
    formData.append("solar_power", building.solar_power ? 1: 0);
    formData.append("mazanine_facility", building.mazanine_facility);
    formData.append("survey_no", building.survey_no);
    formData.append("date_of_completion", this.datepipe.transform(building.date_of_completion, 'yyyy-MM-dd'));
    formData.append("borewell_facility_code", building.borewell_facility);

    return this.http.post<ApiResponse<BuildingResponse>>(`${baseUrl}/${Constants.createBuilding}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<BuildingResponse>>('createBuilding'))
    );
  }

  public updateBuilding(building: UpdateBuildingRequest): Observable<ApiResponse<BuildingResponse>> {
    var formData: any = new FormData();
    formData.append("building_key", building.building_key);
    formData.append("project_key", building.project_key);
    formData.append("building_no", building.building_no);
    formData.append("center_height", building.center_height);
    formData.append("side_height", building.side_height);
    formData.append("area_builtup", building.area_builtup);
    formData.append("area_carpet", building.area_carpet);
    formData.append("gala", building.gala);
    formData.append("dock", building.dock);
    formData.append("shutter", building.shutter);
    formData.append("sliding_ramp", building.sliding_ramp);
    formData.append("auto_dock", building.auto_dock);
    formData.append("in_side_length", building.in_side_length);
    formData.append("out_side_length", building.out_side_length);
    formData.append("type_of_permission_code", building.type_of_permission_code);
    formData.append("is_noc", building.is_noc ? 1: 0);
    formData.append("front_side_road", building.front_side_road);
    formData.append("is_fire_system", building.is_fire_system ? 1: 0);
    formData.append("building_age", building.building_age);
    formData.append("is_stability_certificate", building.is_stability_certificate ? 1: 0);
    formData.append("is_fire_noc", building.is_fire_noc ? 1: 0);
    formData.append("no_of_lights", building.no_of_lights);
    formData.append("electricity_connection", building.electricity_connection);
    formData.append("water_supply_code", building.water_supply);
    formData.append("no_of_toilet", building.no_of_toilet);
    formData.append("toilet_code", building.toilet);
    formData.append("fire_exit", building.fire_exit ? 1: 0);
    formData.append("solar_power", building.solar_power ? 1: 0);
    formData.append("mazanine_facility", building.mazanine_facility);
    formData.append("survey_no", building.survey_no);
    formData.append("date_of_completion", this.datepipe.transform(building.date_of_completion, 'yyyy-MM-dd'));
    formData.append("borewell_facility_code", building.borewell_facility);

    return this.http.post<ApiResponse<BuildingResponse>>(`${baseUrl}/${Constants.updateBuilding}`,formData)
    .pipe(
      map(res => res),
      catchError(this.handleError<ApiResponse<BuildingResponse>>('updateBuilding'))
    );
  }
}
