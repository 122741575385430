import { Project } from "src/app/services/models/project/project.model";
import { ProjectResponse } from "src/app/services/models/project/projectResponse";

export class ProjectMapper {
    static MapProject(data: ProjectResponse): Project {
        return{
            project_key: data.project_key,
            project_name: data.project_name,
            near_by: data.near_by,
            google_location: data.google_location,
            address_line_1: data.address_line_1,
            address_line_2: data.address_line_2,
            pincode: data.pincode,
            country: data.country,
            state: data.state,
            city: data.city,
            buildings: [],
            created_at: data.created_at,
            created_by: data.created_by,
            updated_at: data.updated_at,
            updated_by: data.updated_by,
        }
    }
}