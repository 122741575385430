import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BuildingMapper } from 'src/app/helper/mapper/building-mapper';
import { BuildingService } from 'src/app/services/building.service';
import { Building } from 'src/app/services/models/building/building.model';
import { CreateBuildingComponent } from './create-building/create-building.component';
import { EditBuildingComponent } from './edit-building/edit-building.component';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss']
})
export class BuildingsComponent implements OnInit {

  totalLength = 0;
  displayedColumns: string[] = ['building_no', 'actions'];
  buildingDataSource: MatTableDataSource<Building>;

  @ViewChild(MatSort, { static:true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static:true }) paginator!: MatPaginator;

  constructor(private buildingService: BuildingService,public dialog: MatDialog) { 
    this.buildingDataSource = new MatTableDataSource<Building>();
    this.loadBuildings();
  }

  loadBuildings(){
    this.buildingService.getBuildings()
    .subscribe(
      resp => {
        const buildings: Building[] = [];
        resp.forEach(building => {
          buildings.push(BuildingMapper.MapBuilding(building));
        });
        this.buildingDataSource.data = buildings;
        this.totalLength = this.buildingDataSource.data.length;
      }
    );
  }


  ngOnInit(): void {
    this.buildingDataSource.sort = this.sort;
    this.buildingDataSource.paginator = this.paginator;
  }

  openCreateBuildingDialog(): void {
    const dialogRef = this.dialog.open(CreateBuildingComponent, {
      width: '650px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadBuildings();
    });
  }

  openEditBuildingDialog(building: Building){
    const dialogRef = this.dialog.open(EditBuildingComponent, {
      width: '650px',
      data: {
        building: building
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadBuildings();
    });
  }

  applyFilter(event: any){
    const filterValue = (event.target as HTMLInputElement).value;

    this.buildingDataSource.filter = filterValue.trim().toLowerCase();
  }

  view(building: Building){
    alert('View Clicked');
  }

}
