import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserMapper } from 'src/app/helper/mapper/user-mapper';
import { User } from 'src/app/services/models/user/user.model';
import { UsersService } from 'src/app/services/users.service';
import { CreateUserComponent } from './create-user/create-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';

/**
 * User Component
 */

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  // users: User[];
  totalLength = 0;
  displayedColumns: string[] = ['name', 'email', 'status', 'actions'];
  userDataSource: MatTableDataSource<User>;

  @ViewChild(MatSort, { static:true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static:true }) paginator!: MatPaginator;

  constructor(private userService: UsersService,public dialog: MatDialog) { 
    // this.users = [];
    this.userDataSource = new MatTableDataSource<User>(); 
    this.loadUsers();
  }

  loadUsers(){
    this.userService.getUsers()
    .subscribe(
      resp => {
        const users: User[] = [];
        resp.forEach(user => {
          users.push(UserMapper.MapUser(user));
        });
        this.userDataSource.data = users;
        this.totalLength = this.userDataSource.data.length;
      }
    );
  }

  ngOnInit(): void {
    this.userDataSource.sort = this.sort;
    this.userDataSource.paginator = this.paginator;
  }

  openCreateUserDialog(): void {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '450px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadUsers();
    });
  }

  openEditUserDialog(user: User){
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '450px',
      data: {
        user: user
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadUsers();
    });
  }

  applyFilter(event: any){
    const filterValue = (event.target as HTMLInputElement).value;

    this.userDataSource.filter = filterValue.trim().toLowerCase();
  }
}
