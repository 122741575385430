import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate, CanLoad {

  constructor (private authService: AuthenticationService, private router: Router) {}

  canActivate(){
    return this.canLoad();
  }
  canLoad(){
    if(!this.authService.isLoggedIn()){
      this.router.navigate(['/login']);
    }
    return this.authService.isLoggedIn();
  }
}
