import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateProjectRequest } from 'src/app/services/models/project/createProjectRequest';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {


  public createProjectFormGroup!: FormGroup;

  constructor( public dialogRef: MatDialogRef<CreateProjectComponent>,
    private formbuilder: FormBuilder,
    private projectService: ProjectService,
    private _snackBar: MatSnackBar) {
      this.createForm();
  }


     createForm() {
      this.createProjectFormGroup = this.formbuilder.group({
        projectName: ['', Validators.required],
        nearBy: ['', Validators.required],
        googleLocation: [''],
        addressLine1: ['', Validators.required],
        addressLine2: [''],
        pincode: ['', Validators.required],
        country: ['', Validators.required],
        state: ['', Validators.required],
        city: ['', Validators.required],
      });
    }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createProject(){
    if(this.createProjectFormGroup.valid){
      var createProjectRequest: CreateProjectRequest = {
        project_name: this.createProjectFormGroup.controls.projectName.value,
        near_by: this.createProjectFormGroup.controls.nearBy.value,
        google_location: this.createProjectFormGroup.controls.googleLocation.value,
        address_line_1: this.createProjectFormGroup.controls.addressLine1.value,
        address_line_2: this.createProjectFormGroup.controls.addressLine2.value,
        pincode: this.createProjectFormGroup.controls.pincode.value,
        country: this.createProjectFormGroup.controls.country.value,
        state: this.createProjectFormGroup.controls.state.value,
        city: this.createProjectFormGroup.controls.city.value,
      }
      this.projectService.createProject(createProjectRequest).subscribe(
        resp => {
          if(resp.status = 'success'){
            this.dialogRef.close();
            this.openSnackBar("Project created successfully","");
          }
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
