import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import { Constants } from '../config/constants';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { PickListResponse } from './models/picklist/pickListResponse';
import { ApiResponse } from './models/response';

@Injectable({
  providedIn: 'root'
})
export class PickListService {

  private handleError: HandleError;

  constructor(private http: HttpClient, 
    private httpErrorHandler: HttpErrorHandlerService) {
    this.handleError = httpErrorHandler.createHandleError('PickListService');
   }

  public getList(listCode: string): Observable<PickListResponse[]> {
    return this.http.get<ApiResponse<PickListResponse[]>>(`${baseUrl}/${Constants.getList}${listCode}`)
    .pipe(
      map(res => res.data),
      catchError(this.handleError<PickListResponse[]>('getList'))
    );
  }
}
