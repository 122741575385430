import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private authService: AuthenticationService,
    private router: Router){
  }
  
  canActivate() {
    if(this.authService.isLoggedIn()){
      this.router.navigate(['/dashboard']);
    }
    return !this.authService.isLoggedIn();
  }
}
