  <h1>Users</h1>
  <div class="top-menu-container">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Ex. username" (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="createButton">
      <button color="primary" (click)="openCreateUserDialog()" mat-raised-button>Create</button> 
    </div>
  </div>
  <div>
    <table mat-table [dataSource]="userDataSource" class="mat-elevation-z0" matSort>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon class="check-circle">check_circle</mat-icon>
        </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
        <td mat-cell *matCellDef="let element"> 
          <button mat-icon-button >
            <mat-icon color="primary" (click)="openEditUserDialog(element)">edit</mat-icon>
          </button>
          <button mat-icon-button >
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="totalLength" [pageSize]="5" [pageSizeOptions]="[5,10,50,100]"></mat-paginator> 
  </div>