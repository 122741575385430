import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { MessageService } from './message.service';

export type HandleError =
<T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

@Injectable()
export class HttpErrorHandlerService {

  constructor(private messageService: MessageService) { }

  createHandleError = (serviceName = '') => <T> 
  (operation = 'operation', result = {} as T) => this.handleError(serviceName,operation,result);

  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {
    return (error: HttpErrorResponse): Observable<T> => {
      const message = (error.error instanceof ErrorEvent)?
        error.error.message :
        `server returned code ${error.status} with body "${error.error}"`;
      this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);

      if(error.error != null){
        return throwError(error);
      }

      return EMPTY;
    }
  }
}
