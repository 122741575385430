import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { Constants } from 'src/app/config/constants';
import { PickupListCode } from 'src/app/config/pickupListCode.enum';
import { PicklistMapper } from 'src/app/helper/mapper/picklist-mapper';
import { ProjectMapper } from 'src/app/helper/mapper/project-mapper';
import { BuildingService } from 'src/app/services/building.service';
import { CreateBuildingRequest } from 'src/app/services/models/building/createBuildingRequest';
import { PickList } from 'src/app/services/models/picklist/picklistItem.model';
import { PickListResponse } from 'src/app/services/models/picklist/pickListResponse';
import { Project } from 'src/app/services/models/project/project.model';
import { ProjectResponse } from 'src/app/services/models/project/projectResponse';
import { PickListService } from 'src/app/services/pick-list.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-create-building',
  templateUrl: './create-building.component.html',
  styleUrls: ['./create-building.component.scss']
})
export class CreateBuildingComponent implements OnInit {

  projectList: Project[] = [];
  typeOfPermissions: PickList[] = [];
  waterSupplyTypes: PickList[] = [];
  toiletTypes: PickList[] = [];
  borewellFacilites: PickList[] = [];
  
  
  public createBuildingFormGroup!: FormGroup;
  dataLoaded: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<CreateBuildingComponent>,
    private formbuilder: FormBuilder,
    private projectService: ProjectService,
    private pickListService: PickListService,
    private buildingService: BuildingService,
    private _snackBar: MatSnackBar
  ) { 
    this.createForm();
  }

  createForm() {
    this.createBuildingFormGroup = this.formbuilder.group({
      projectRefKey: ['', Validators.required],
      buildingNumber: ['', Validators.required],
      centerHeight: [0, ],
      sideHeight: [0, ],
      areaBuiltup: [0, ],
      areaCarpet: [0, ],
      gala: [0, ],
      dock: [0, ],
      shutter: [0, ],
      slidingRamp: [0, ],
      autoDock: [0, ],
      inSideLength: [0, ],
      outSideLength: [0, ],
      typeOfPermissionCode: ['', ],
      isNoc : [false],
      frontSideRoad : [0, ],
      isFireSystem : [false, ],
      buildingAge : [0, ],
      isStabilityCertificate : [false, ],
      isFireNoc : [false, ],
      noOfLights : [0, ],
      electricityConnection : ['', ],
      waterSupply : ['', ],
      noOfToilet : [0, ],
      toilet : [0, ],
      fireExit : [false,],
      solarPower : [false,],
      mazanineFacility : [false,],
      surveyNo : [0,],
      dateOfCompletion : [null, ],
      borewellFacility : ['', ]
    });
    this.dataLoaded = false;
    this.loadLookupValues();
  }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  loadLookupValues() {
    this.projectService.getProjects()
    .pipe(
      take(1)
    )
    .subscribe((projects: ProjectResponse[]) => {
      if(projects.length > 0) {
        this.projectList = projects.map(project => ProjectMapper.MapProject(project));
        this.dataLoaded = true;
      }
    })
    this.pickListService.getList(PickupListCode.TypeOfPermission)
    .pipe(
      take(1)
    )
    .subscribe((typeOfPermissions: PickListResponse[]) => {
      if(typeOfPermissions.length > 0){
        this.typeOfPermissions = typeOfPermissions.map(typeOfPermission => PicklistMapper.MapPickListItem(typeOfPermission));
      }
    })
    this.pickListService.getList(PickupListCode.WaterSupplyType)
    .pipe(
      take(1)
    )
    .subscribe((waterSupplyTypes: PickListResponse[]) => {
      if(waterSupplyTypes.length > 0){
        this.waterSupplyTypes = waterSupplyTypes.map(waterSupplyType => PicklistMapper.MapPickListItem(waterSupplyType));
      }
    })

    this.pickListService.getList(PickupListCode.ToiletType)
    .pipe(
      take(1)
    )
    .subscribe((toiletTypes: PickListResponse[]) => {
      if(toiletTypes.length > 0){
        this.toiletTypes = toiletTypes.map(toiletType => PicklistMapper.MapPickListItem(toiletType));
      }
    })

    this.pickListService.getList(PickupListCode.BorewellFacilityType)
    .pipe(
      take(1)
    )
    .subscribe((borewellFacilites: PickListResponse[]) => {
      if(borewellFacilites.length > 0){
        this.borewellFacilites = borewellFacilites.map(borewellFacility => PicklistMapper.MapPickListItem(borewellFacility));
      }
    })
  }

  changeEvent(){
  }

  createBuilding(){
    if(this.createBuildingFormGroup.valid){
      var createBuildingRequest: CreateBuildingRequest = {
        project_key: this.createBuildingFormGroup.controls.projectRefKey.value,
        building_no: this.createBuildingFormGroup.controls.buildingNumber.value,
        center_height: this.createBuildingFormGroup.controls.centerHeight.value,
        side_height: this.createBuildingFormGroup.controls.sideHeight.value,
        area_builtup: this.createBuildingFormGroup.controls.areaBuiltup.value,
        area_carpet: this.createBuildingFormGroup.controls.areaCarpet.value,
        gala: this.createBuildingFormGroup.controls.gala.value,
        dock: this.createBuildingFormGroup.controls.dock.value,
        shutter: this.createBuildingFormGroup.controls.shutter.value,
        sliding_ramp: this.createBuildingFormGroup.controls.slidingRamp.value,
        auto_dock: this.createBuildingFormGroup.controls.autoDock.value,
        in_side_length: this.createBuildingFormGroup.controls.inSideLength.value,
        out_side_length: this.createBuildingFormGroup.controls.outSideLength.value,
        type_of_permission_code: this.createBuildingFormGroup.controls.typeOfPermissionCode.value,
        is_noc: this.createBuildingFormGroup.controls.isNoc.value,
        front_side_road: this.createBuildingFormGroup.controls.frontSideRoad.value,
        is_fire_system: this.createBuildingFormGroup.controls.isFireSystem.value,
        building_age: this.createBuildingFormGroup.controls.buildingAge.value,
        is_stability_certificate: this.createBuildingFormGroup.controls.isStabilityCertificate.value,
        is_fire_noc: this.createBuildingFormGroup.controls.isFireNoc.value,
        no_of_lights: this.createBuildingFormGroup.controls.noOfLights.value,
        electricity_connection: this.createBuildingFormGroup.controls.electricityConnection.value,
        water_supply: this.createBuildingFormGroup.controls.waterSupply.value,
        no_of_toilet: this.createBuildingFormGroup.controls.noOfToilet.value,
        toilet: this.createBuildingFormGroup.controls.toilet.value,
        fire_exit: this.createBuildingFormGroup.controls.fireExit.value,
        solar_power: this.createBuildingFormGroup.controls.solarPower.value,
        mazanine_facility: this.createBuildingFormGroup.controls.mazanineFacility.value,
        survey_no: this.createBuildingFormGroup.controls.surveyNo.value,
        date_of_completion: this.createBuildingFormGroup.controls.dateOfCompletion.value,
        borewell_facility: this.createBuildingFormGroup.controls.borewellFacility.value,
      }
      this.buildingService.createBuilding(createBuildingRequest).subscribe(
        resp => {
          if(resp.status = 'success'){
            this.dialogRef.close();
            this.openSnackBar("Building created successfully","");
          }
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
