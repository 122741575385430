import { PickList } from "src/app/services/models/picklist/picklistItem.model";
import { PickListResponse } from "src/app/services/models/picklist/pickListResponse";

export class PicklistMapper {
    static MapPickListItem(data: PickListResponse): PickList{
        return {
            lookup_code: data.lookup_code,
            lookup_value: data.lookup_value,
            lookup_description: data.lookup_description,
            default_value: data.default_value,
        }
    }
}