import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon';

// Local Componenet Import
import { MediaMatcher } from '@angular/cdk/layout'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatTableModule } from '@angular/material/table'
import { MatCardModule } from '@angular/material/card'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatExpansionModule } from '@angular/material/expansion'

// Services Import
import { AuthenticationService } from './auth/services/authentication.service';
import { AuthGuard } from './auth/guards/auth.guard';
import { DefaultModule } from './layouts/default/default.module';
import { MessageService } from './services/message.service';
import { HttpErrorHandlerService } from './services/http-error-handler.service';
import { CreateUserComponent } from './modules/users/create-user/create-user.component';
import { EditUserComponent } from './modules/users/edit-user/edit-user.component';
import { ProjectsComponent } from './modules/projects/projects.component';
import { BuildingsComponent } from './modules/buildings/buildings.component';
import { ProjectDetailsViewComponent } from './modules/projects/project-details-view/project-details-view.component';
import { CreateProjectComponent } from './modules/projects/create-project/create-project.component';
import { EditProjectComponent } from './modules/projects/edit-project/edit-project.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CreateBuildingComponent } from './modules/buildings/create-building/create-building.component';
import { EditBuildingComponent } from './modules/buildings/edit-building/edit-building.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { DatePipe } from '@angular/common';
import { InvestorsComponent } from './modules/investors/investors.component';
import { CreateInvestorComponent } from './modules/investors/create-investor/create-investor.component';
import { EditInvestorComponent } from './modules/investors/edit-investor/edit-investor.component';

@NgModule({
  declarations: [
    AppComponent,
    CreateUserComponent,
    EditUserComponent,
    ProjectsComponent,
    BuildingsComponent,
    ProjectDetailsViewComponent,
    CreateProjectComponent,
    EditProjectComponent,
    CreateBuildingComponent,
    EditBuildingComponent,
    InvestorsComponent,
    CreateInvestorComponent,
    EditInvestorComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatCardModule,
    DefaultModule,
    MatGridListModule,
    MatExpansionModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatOptionModule,  
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule
  ],
  providers: [
    AuthenticationService,
    MessageService,
    HttpErrorHandlerService,
    AuthGuard,
    MediaMatcher,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
