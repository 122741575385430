<h2 mat-dialog-title>Edit Project</h2>
<mat-dialog-content>
  <form class="login-form" [formGroup]="editProjectFormGroup">
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Name</mat-label>
      <input type="text" required formControlName="projectName" matInput placeholder="Name">
      <mat-error *ngIf="editProjectFormGroup.hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Near By</mat-label>
      <input type="text" required formControlName="nearBy" matInput placeholder="Near By">
      <mat-error *ngIf="editProjectFormGroup.hasError('required')">
        Near By is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Google Location</mat-label>
      <input type="text" formControlName="googleLocation" matInput placeholder="Google Location">
    </mat-form-field>

    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Address Line 1</mat-label>
      <input type="text" required formControlName="addressLine1" matInput placeholder="Address Line 1">
      <mat-error *ngIf="editProjectFormGroup.hasError('required')">
        Address Line 1 is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Address Line 2</mat-label>
      <input type="text" formControlName="addressLine2" matInput placeholder="Address Line 2">
    </mat-form-field>

    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Pincode</mat-label>
      <input type="text" required formControlName="pincode" matInput placeholder="Pincode">
      <mat-error *ngIf="editProjectFormGroup.hasError('required')">
        Pincode is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>Country</mat-label>
      <input type="text" required formControlName="country" matInput placeholder="Country">
      <mat-error *ngIf="editProjectFormGroup.hasError('required')">
        Country is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>State</mat-label>
      <input type="text" required formControlName="state" matInput placeholder="State">
      <mat-error *ngIf="editProjectFormGroup.hasError('required')">
        State is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input" appearance="fill">
      <mat-label>City</mat-label>
      <input type="text" required formControlName="city" matInput placeholder="City">
      <mat-error *ngIf="editProjectFormGroup.hasError('required')">
        City is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button  class="app-container float-right"  mat-raised-button color="primary" (click)='onNoClick()'>Cancel</button>
    <button  class="app-container float-right" [disabled]="!editProjectFormGroup.valid" mat-raised-button color="primary" (click)='updateProject()'>Save</button>
</mat-dialog-actions>