<app-header (toggleSideBarForMe)="sideBarToggler()"></app-header>
<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <mat-card>
            <div *ngIf="loaderService.isLoading | async" class="loader-container">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="mat-drawer-content-container">
                <router-outlet></router-outlet>
            </div>
        </mat-card>
    </mat-drawer-content>
</mat-drawer-container>
<app-footer></app-footer>