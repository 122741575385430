import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { BuildingMapper } from 'src/app/helper/mapper/building-mapper';
import { ProjectMapper } from 'src/app/helper/mapper/project-mapper';
import { Building } from 'src/app/services/models/building/building.model';
import { Project } from 'src/app/services/models/project/project.model';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-project-details-view',
  templateUrl: './project-details-view.component.html',
  styleUrls: ['./project-details-view.component.scss']
})
export class ProjectDetailsViewComponent implements OnInit {

  selectedProject: Project = new Project();

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  constructor(private route: ActivatedRoute, 
    private projectService: ProjectService) { 
    }

  ngOnInit(): void {
    let key = this.route.snapshot.paramMap.get('key') ?? '';
    if (key){
      let projectResponse = this.projectService.getProjectByKey(key);
      if (projectResponse) {
        projectResponse.subscribe(resp => {
          if (resp.data) {
            this.selectedProject = ProjectMapper.MapProject(resp.data);
            this.projectService.getBuildingsByProjectKey(key).subscribe(
              resp => {
                resp.forEach(building => {
                  this.selectedProject.buildings.push(BuildingMapper.MapBuilding(building))
                });
              }
            )
          }
        });
      }
    }
  }


  

}
