import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateUserRequest } from 'src/app/services/models/user/createUserRequest';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  public createUserFormGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateUserComponent>,
    private formbuilder: FormBuilder,
    private userService: UsersService,
    private _snackBar: MatSnackBar) {
      this.createForm();
  }

  createForm() {
    this.createUserFormGroup = this.formbuilder.group({
      fullName: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  createUser(){
    if(this.createUserFormGroup.valid){
      var createUserRequest: CreateUserRequest = {
        name: this.createUserFormGroup.controls.fullName.value,
        email: this.createUserFormGroup.controls.email.value,
        password: this.createUserFormGroup.controls.password.value,
        status: 'ACTIVE'
      }
      this.userService.createUser(createUserRequest).subscribe(
        resp => {
          if(resp.status = 'success'){
            this.dialogRef.close();
            this.openSnackBar("User created successfully","");
          }
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
