<h1>Projects</h1>
<div class="top-menu-container">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Search</mat-label>
      <input matInput placeholder="Ex. username" (keyup)="applyFilter($event)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="createButton">
    <button color="primary" (click)="openCreateProjectDialog()" mat-raised-button>Create</button> 
  </div>
</div>
<div>
  <table mat-table [dataSource]="projectDataSource" class="mat-elevation-z0" matSort>

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header > Name </th>
      <td mat-cell *matCellDef="let element"> {{element.project_name}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
      <td mat-cell *matCellDef="let element"> {{element.address_line_1}}, {{element.address_line_2}}, {{element.city}}, {{element.state}}  </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td mat-cell *matCellDef="let element"> 
        <button mat-icon-button >
          <mat-icon color="primary" (click)="openEditProjectDialog(element)">edit</mat-icon>
        </button>
        <button mat-icon-button >
          <mat-icon color="warn">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let element"> 
          <button mat-icon-button >
            <mat-icon color="primary" [routerLink]="['/projects', element.project_key]">arrow_forward_ios</mat-icon>
          </button>
        </td>
      </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="totalLength" [pageSize]="5" [pageSizeOptions]="[5,10,50,100]"></mat-paginator> 
</div>