import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvestorService } from 'src/app/services/investor.service';
import { CreateInvestorRequest } from 'src/app/services/models/investor/createInvestorRequest';

@Component({
  selector: 'app-create-investor',
  templateUrl: './create-investor.component.html',
  styleUrls: ['./create-investor.component.scss']
})
export class CreateInvestorComponent implements OnInit {

  public createInvestorFormGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CreateInvestorComponent>,
    private formbuilder: FormBuilder,
    private investorService: InvestorService,
    private _snackBar: MatSnackBar) {
      this.createForm();
  }
  createForm() {
    this.createInvestorFormGroup = this.formbuilder.group({
      partyName: ['', Validators.required],
      address: ['', Validators.required],
      contactNumber: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      panNumber: ['', Validators.required],
      gstNumber: ['', Validators.required],
      aadharNumber: ['', Validators.required]
    });
  }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  createUser(){
    if(this.createInvestorFormGroup.valid){
      var createUserRequest: CreateInvestorRequest = {
        party_name: this.createInvestorFormGroup.controls.partyName.value,
        address: this.createInvestorFormGroup.controls.address.value,
        contact_no: this.createInvestorFormGroup.controls.contactNumber.value,
        email: this.createInvestorFormGroup.controls.email.value,
        pan_no: this.createInvestorFormGroup.controls.panNumber.value,
        gst_no: this.createInvestorFormGroup.controls.gstNumber.value,
        aadhar_no: this.createInvestorFormGroup.controls.aadharNumber.value,
      }
      this.investorService.createInvestor(createUserRequest).subscribe(
        resp => {
          if(resp.status = 'success'){
            this.dialogRef.close();
            this.openSnackBar("Investor created successfully","");
          }
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
