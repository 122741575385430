import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // isUserLoggedIn: boolean = false;

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly Email = 'EMAIL';
  private readonly Name = 'NAME';
  private readonly UserKey = 'USER_KEY';
  private loggedUser: string | undefined;


  constructor(private myRoute: Router,private http:HttpClient) { }

  login(user: {email: string, password: string}): Observable<any>{
    var formData: any = new FormData();
    formData.append("email", user.email);
    formData.append("password", user.password);
    return this.http.post(`${baseUrl}/authorization.php?type=login`,formData)
      .pipe(
        tap(tokens => this.doLoginUser(user.email, tokens)),
        mapTo(true),
        catchError(error => {
          alert(error.error);
          return of(false);
        }));
  }

  doLoginUser(email: string, tokens: Object): void {
    this.loggedUser = email;
    this.storeTokens(tokens);
  }

  storeTokens(tokens: any) {
    localStorage.setItem(this.JWT_TOKEN, tokens.data.auth_info);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.data.refreshToken);
    localStorage.setItem(this.Email, tokens.data.user_info.email);
    localStorage.setItem(this.Name, tokens.data.user_info.name);
    localStorage.setItem(this.UserKey, tokens.data.user_info.user_key);
  }

  logout() {
    this.doLogoutUser();
    this.myRoute.navigate(['/login']);
  }

  doLogoutUser() {
    this.loggedUser = undefined;
    this.removeToken();
  }

  removeToken() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.Email);
    localStorage.removeItem(this.Name);
    localStorage.removeItem(this.UserKey);
  }

  refreshToken(){
    return this.http.post<any>(`${baseUrl}/authorization.php?type=refresh`,{
      'refreshToekn' : this.getRefreshToken()
    }).pipe(tap((tokens: any) => {
      this.storeTokens(tokens.jwt)
    }))
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  getEmail(): string   {
    return localStorage.getItem(this.Email) ?? '';
  }

  getName(): string  {
    return localStorage.getItem(this.Name) ?? '';
  }

  getUserKey(): string   {
    return localStorage.getItem(this.UserKey) ?? '';
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN) ?? '';
  }

  isLoggedIn(){
    return !!this.getJwtToken();
  }

}
