import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-photo-component',
  templateUrl: './avatar-photo-component.component.html',
  styleUrls: ['./avatar-photo-component.component.scss']
})
export class AvatarPhotoComponentComponent implements OnInit {

  @Input() public photoUrl!: string;
  @Input() public name!: string;

  public showInitials = false;
  public initials!: string;
  public circleColor!: string; 
  private colors = [
    '#162447',
    '#1F4068',
    '#1B1B2F',
    '#E43F5A',
  ]


  constructor() { }

  ngOnInit(): void {
    if(!this.photoUrl){
      this.showInitials = true;
      this.createInitials();

      const randomindex = Math.floor(Math.random() * Math.floor(this.colors.length));
      this.circleColor = this.colors[randomindex];
    }
  }
  private createInitials() {
    let initials = "";

    for (let i = 0; i < this.name.length; i++) {
      if(this.name.charAt(i) == ' '){
        continue;
      }

      if (this.name.charAt(i) === this.name.charAt(i).toUpperCase()) {
        initials += this.name.charAt(i);
        
        if (initials.length == 2){
          break;
        }
      }
    }

    this.initials = initials;
  }

}
